(function($) {


  get_viewport_size = function() {
    var e = window,
    a = 'inner';
    if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    return { width: e[a + 'Width'], height: e[a + 'Height'] };
  };


  stickyNav = function()  {
    var el = document.querySelector('.header__menu');
    var height = el.scrollHeight;
    el.style.setProperty('--max-height', height + 'px');
    
    if (get_viewport_size().width > 1200) {
      if ( $(window).scrollTop() > 150  ) {
        $('body').addClass('stickyNav');
      } else {
        $('body').removeClass('stickyNav');
      }
    } else if (get_viewport_size().width < 1200) {
      // if ( $(window).scrollTop() > 150  ) {
      //   $('body').addClass('stickyNav');
      // } else {
      //   $('body').removeClass('stickyNav');
      // }
    } 
  }

  homeCarousel = function() {
    $('.home-carousel--slick').slick({
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2500,
      pauseOnHover: true,
      prevArrow: '<a class="slick-arrow prev-arrow"><svg width="20" height="36" xmlns="http://www.w3.org/2000/svg"><path d="M18 2L2 18l16 16" fill-rule="nonzero" stroke="#EC6643" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg></a>',
      nextArrow: '<a class="slick-arrow next-arrow"><svg width="20" height="36" xmlns="http://www.w3.org/2000/svg"><path d="M18 2L2 18l16 16" fill-rule="nonzero" stroke="#EC6643" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg></a>',
      arrows: false,
      speed: 300,
      slidesToShow: 3,
      fade: false,
      slidesToScroll: 1,
      centerMode: true,
      responsive: [
      {
        breakpoint: 768,
        settings: {
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          autoplay: false,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
      ]
    });
  }


   newsCarousel = function() {
    $('.news-carousel--slick').slick({
      dots: true,
      infinite: true,
      autoplay: false,
      prevArrow: '<a class="slick-arrow prev-arrow"><svg width="20" height="36" xmlns="http://www.w3.org/2000/svg"><path d="M18 2L2 18l16 16" fill-rule="nonzero" stroke="#EC6643" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg></a>',
      nextArrow: '<a class="slick-arrow next-arrow"><svg width="20" height="36" xmlns="http://www.w3.org/2000/svg"><path d="M18 2L2 18l16 16" fill-rule="nonzero" stroke="#EC6643" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg></a>',
      arrows: false,
      speed: 300,
      slidesToShow: 3,
      variableWidth: true,
      fade: false,
      slidesToScroll: 1,
      centerMode: false,
      responsive: [
      {
        breakpoint: 768,
        settings: {
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
          centerMode: true,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          autoplay: false,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
      ]
    });
  }


  teamCarousel = function() {
    var wrapper = $('.team-carousel--slick');
    if ($(".slick-initialized").length) {
      wrapper.slick('unslick');
    }
    wrapper.slick({
      mobileFirst: true,
      dots: false,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 5500,
      pauseOnHover: true,
      prevArrow: '<a class="slick-arrow prev-arrow"><svg width="20" height="36" xmlns="http://www.w3.org/2000/svg"><path d="M18 2L2 18l16 16" fill-rule="nonzero" stroke="#EC6643" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg></a>',
      nextArrow: '<a class="slick-arrow next-arrow"><svg width="20" height="36" xmlns="http://www.w3.org/2000/svg"><path d="M18 2L2 18l16 16" fill-rule="nonzero" stroke="#EC6643" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg></a>',
      arrows: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: false,
      centerMode: true,
      responsive: [
      {
        breakpoint: 767,
        settings: "unslick"
      },
      ]
    });
  }

  toggleNav = function() {
    // Togglefunction for the mobile menu. If toggled, a class is added to the body

    $("#nav-trigger").on('click', function(e){
      e.preventDefault();
      if($('body').hasClass('open-nav')) {
        $('body').removeClass('open-nav');
      }
      else {
        $('body').addClass('open-nav');
      }
    });

    $(".menu-item a").on('click', function(e) {
      if( $('body').hasClass('open-nav') ) {
        setTimeout(function(){
          $('body').removeClass('open-nav');
        }, 700);
      }
    });
  };

  footerForms = function() {

    // Togglefunction for the mobile menu. If toggled, a class is added to the body
    $(".footer-contact__callback").on('click', function(e){
      e.preventDefault();
      if($('.footer-contact__pill').hasClass('open-callback')) {
        $('.footer-contact__pill').removeClass('open-callback');
      }
      else {
        $('.footer-contact__pill').removeClass('open-email');
        $('.footer-contact__pill').addClass('open-callback');
      }
    });
    $(".footer-contact__email").on('click', function(e){
      e.preventDefault();
      if($('.footer-contact__pill').hasClass('open-email')) {

        $('.footer-contact__pill').removeClass('open-email');
      }
      else {
        $('.footer-contact__pill').removeClass('open-callback');
        $('.footer-contact__pill').addClass('open-email');
      }
    });
  };

  magnificPopup = function() {
    $('.gallery').magnificPopup({
      delegate: 'a',
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,1] // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      }
    });

    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: true,
      callbacks: {
        beforeOpen: function() {
          startWindowScroll = $(window).scrollTop();
        },
        close: function() {
          $(window).scrollTop(startWindowScroll);
        }
      }
    });
    $('.popup-modal').magnificPopup({
      type: 'inline',
      preloader: false,
      focus: '#name',

    // When elemened is focused, some mobile browsers in some cases zoom in
    // It looks not nice, so we disable it:
    callbacks: {
      beforeOpen: function() {
        if($(window).width() < 700) {
          this.st.focus = false;
        } else {
          this.st.focus = '#name';
        }
      }
    }
  });
  };

  hoverWwd = function() {
    $('.category_item').hover(function() {
      var active = $(this).data("hover-item");
      $('.category_item').not($(this)).addClass('wwd-inactive');
      $('.category_bg[data-hover-bg="' + active + '"]').addClass('hover-bg');
    }, function(){
      $('.category-archive').removeClass('wwd-hover');
      $('.category_item').removeClass('wwd-item-hover');
      $('.category_item').removeClass('wwd-inactive');
      $('.category_bg').removeClass('hover-bg');
    });

  }

  formLabels = function() {
    $(".wpcf7-form input, .wpcf7-form textarea").focus(function() {
      $(this).parent().siblings('label').addClass('has-value');
    })
  // blur input fields on unfocus + if has no value
  .blur(function() {
    var text_val = $(this).val();
    if(text_val === "") {
      $(this).parent().siblings('label').removeClass('has-value');
    }
  });
}
faq = function () {
  //Add Inactive Class To All Accordion Headers
      // $('.accordion-header').toggleClass('inactive-header');
    //Set The Accordion Content Width
    //Open The First Accordion Section When Page Loads
    // $('.accordion-header').first().toggleClass('active-header').toggleClass('inactive-header');
    $('.accordion-content').first().slideDown().toggleClass('open-content');

    // The Accordion Effect
    $('.accordion-header').click(function () {
      if($(this).is('.inactive-header')) {
        $('.active-header').toggleClass('active-header').toggleClass('inactive-header').next().slideToggle().toggleClass('open-content');
        $(this).toggleClass('active-header').toggleClass('inactive-header');
        $(this).next().slideToggle().toggleClass('open-content');
      }
    });

    return false;
  };

  animateAnchor = function() {
    $('a[href^="#"]:not(.popup-modal)').click(function(){

      var the_id = $(this).attr("href");
      $('html, body').animate({
        scrollTop:$(the_id).offset().top - 100
      }, 1000);

      $('body').removeClass('open-nav');
      return false;
    });
  };

  getUrlParameter = function(sParam) {
    var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  };

})(jQuery);